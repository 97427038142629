@import "../../../styles/styles";

.selectProxy {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  border: 1px solid #394e74;
  text-decoration: none;
  border-bottom: none !important;
  border-bottom-color: #394e74;
  padding-left: 13px !important;
}