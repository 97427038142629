@import "../../../styles/styles";

.contributor-list-root {
  margin-top: 25px;

  .label-bold {
    font-weight: 500;
  }
}

.contributor-list-container {
  margin-top: 7px;
  border-radius: 2px;
  border: 1px solid rgb(216, 216, 216);

  & .loader-icon {
    height: 40px;
    width: 40px;
  }

  & .contributor-list {
    max-height: 185px;
    overflow: scroll;

    & .list-item-even {
      background-color: var(--lightGrayColor);
    }

    & .contributor-avatar {
      margin-top: 5px;
      font-size: 38px;
    }

    & .icon-delete {
      margin-top: 5px;
    }
  }

}
