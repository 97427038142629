@import '../../styles/styles';
@import '../resource/create-resource.module.scss';

.root {
  width: auto;
  min-width: 420px;
  margin: 15px 15px 15px 0;
}

.header {
  background-color: #2a3b5a;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
}

.content {
  background-color: white;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
}

.label {
  color: #394e74;
}

.slider_input {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.fileshare_slider {
  margin-top: auto;
  margin-bottom: auto;
  width: 80%;
}

.new-fileshare-size-container {
  display: flex;

  &__button {
    margin-left: auto;
  }
}

.update-loader {
  width: 30px;
  height: 30px;
}

.dialogTitle {
  width: auto;
  height: 22px;
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  color: var(--darkBlueColor);
}

.accessAttributionButtonGrid {
  margin-bottom: 36px;
}

.validationButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
  background-color: #4494d0;
}

.cancelButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
}