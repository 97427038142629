@import "../header/header-styles.module";

.menuItemButton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
}

.menuItemButtonText {
    text-transform: none;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: normal;
    color: grey;
}

.text {
    padding-left: 5px;
    color: grey
  }