body {
    font-family: 'Roboto', sans-serif;
    margin: 0 8px 0 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #FFFF;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.warning-centered-text {
  text-align: center;
  font-size: 1.1em;
}
