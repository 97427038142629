@import "../../../styles/styles";

.loaderContainer {
  margin-top: 100px;
  text-align: center;
}

.instanceListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  &>div {
    margin-top: 5px;
  }

  margin-left: 70px;
}

.sliderRoot {
  width: 331px;
}

.sliderContainer {
  padding: 22px 0px;
}

.title {
  @include titre
}

.unselectable {
  @include unselectable
}

.tablePagination {
  position: absolute;
  bottom: 0;
  right: 0;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}


.alert {
  flex-grow: 1;
  margin-top: 20px;
}