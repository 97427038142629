@import "config";

@mixin unselectable {
    user-select: none;
}

@mixin titre {
    width: auto;
    height: 28px;
    font-size: 24px;
    font-weight: 500;
    font-style: var(--normalFontStyle);
    font-stretch: var(--normalFontStretch);
    line-height: var(--normalLineHeight);
    letter-spacing: var(--normalLetterSpacing);
    color: var(--darkRedColor);
    margin-left: 70px;
    margin-top: 41px;
}
