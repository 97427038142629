@import "../shared/menu/menu-item.module.scss";

.project_list_loader {
  margin-top: 20px;
  margin-left: 160px;
  height: 40px;
  width: 40px;
}

.sidebar {
  font-weight: 500;
  color: var(--whiteColor);

  &__divider {
    box-shadow: 0 0 12px 0 rgba(113, 113, 113, 0.5);
    height: 1px;
    background-color: var(--lightBlueColor);
    margin-top: 10px;
  }

  &__icon_hidden {
    visibility: hidden;
  }

  // Title
  &__titleLink {
    text-decoration: none;
    color: var(--whiteColor);

    &__titleVersionContainer {
      display: flex;
      align-items: baseline;
      justify-content: center;
      user-select: none;
    }

    &__sismageTitle {
      height: 36px;
      font-size: 24px;
      letter-spacing: 3.2px;
      margin-top: 17px;
      color: var(--whiteColor);
    }

    &__sismageVersion {
      font-size: 14px;
      font-weight: 300;
      margin-left: 9px;
    }
  }

  // Search

  &__inputSearch {
    width: 100%;
    color: var(--whiteColor) !important;

    &__searchIcon {
      color: var(--whiteColor);
    }

    &__inputSearchLabel {
      width: 67px;
      height: 18px;
      font-size: 16px;
      color: var(--grayColor);
    }

    &__input {
      color: var(--whiteColor);
    }

    &__inputSearchUnderline {
      border-bottom: 1px solid var(--whiteColor);
    }

    &__inputSearchFocused {
      color: var(--whiteColor);
    }
  }

  &__loader {
    width: 24px;
    height: 24px;
    margin-right: 14px;
  }

  &__action_icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    color: var(--whiteColor);
    min-width: 0;
  }


    &__action_icon_expired {
      color: var(--orangeColor);
    }

  &__hard_disk_zone_icon {
    height: 11.11px;
    width: 11.11px;
    margin-right: 8px;
  }

  // Site/zone/project
  &__site_list {
    padding: 0 8px 0 0;
  }

  &__zone_list {
    margin-left: 30px;
    padding: 0;
  }

  &__project_list {
    padding: 0;
  }

  &__list_item_container {
    padding: 5px 0;

    &__list_item_container_site {
      padding-left: 16px;
    }

    &__list_item_container_zone {
      padding-left: 0px;
    }
  }

  &__list_item {
    display: flex;
    width: 100%;
    justify-content: center;

    &> :first-child {
      flex: auto;
      align-items: center;
    }

    &>div {
      display: flex;
    }
  }

  &__project_list_item {
    padding-left: 30px;
  }

  &__nav_item_text {
    height: 19px;
    font-size: 16px;
    font-weight: 500;
    color: var(--whiteColor);

    &:focus {
      color: var(--whiteColor);
    }
  }

  &__overflow_ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  // TODO flag
  &__country_flag {
    font-size: 16px;
    margin-right: 8px;
  }

  &__custom_link {
    &:visited {
      text-decoration: none;
      color: currentColor !important;
    }
  }

  &__link {
    text-decoration: none;
    min-width: 200px;

    &__button_link {
      color: var(--whiteColor);
      font-weight: 500;
      padding-left: 20px;
      justify-content: left;
      height: 41px;
      margin-top: 24px;

      border-radius: 0 20px 20px 0;

      &__link_label {
        padding-left: 15px;
      }

      &:focus {
        background-color: #394e74;
      }

      &__sessions_number {
        color: var(--darkBlueColor);
        background-color: var(--whiteColor);
        width: 21px;
        height: 21px;
        font-size: small;
        margin-left: 15px;
      }
    }
  }

  &__action_menu_dropdown_icon {
    color: var(--darkBlueColor);
  }

  &__item_label {
    margin-left: 10px;
  }

  &__item_label_disabled {
    margin-left: 10px;
    color: var(--darkGrayColor);
    font-style: italic;
  }

  &__icon_link {
    text-decoration: none;
    color: black;
  }

  &__download_icon_reversed {
    transform: rotate(180deg);
  }

  &__project_list_loader {
    margin-top: 20px;
    margin-left: 160px;
    height: 40px;
    width: 40px;
  }

  &__icon {
    margin-left: 9px;
    color: var(--whiteColor);
  }

  &__icon_disabled {
    margin-left: 9px;
    color: var(--grayColor);
  }

  &__project_icon {
    margin-right: 4px !important;
    padding: 0px 2px;
    min-width: 0;
  }

  &__project_arrow {
    margin-left: 9px;
    color: var(--whiteColor);
  }

  &__indent{
    padding-left: 30px;
  }

  &__project_leaf{
    padding-left: 30px;
  }

  &__folder_icon {
    margin-right: 4px !important;
    padding: 0px 2px;
    min-width: 0;
    color: var(--whiteColor);
  }

}