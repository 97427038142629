@import "../../styles/styles";
@import "../shared/menu/menu-item.module.scss";

.dialogTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: red;
}

.icon {
  width: 24px;
  height: 20px;
  color: #3d4657;
  margin-right: 12px;
}

.addFileTitle {
  width: auto;
  height: 20px;
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  color: var(--darkBlueColor);
}

.resourceName {
  width: 284px;
  height: 19px;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  text-align: start;
  color: #4a4a4a;
  display:flex;
}

.fileInputContainer {
  margin-left: 21px;
  margin-right: 21px;
}

.fileInputContainer input {
  width: 95%;
  font: 13px Roboto, sans-serif;
  padding: 10px;
}

.infoMessage {
  margin: 5px 21px 0 21px;
  font-size: 13px;
  font-weight: 500;
}

.disclaimerMessage {
  margin: 5px 21px 0 21px;
  font-size: 11px;
  font-weight: 500;
}

.errorMessage {
  margin: 5px 21px 0 21px;
  font-size: 11px;
  font-weight: 500;
  color: red;
}

.accessAttributionButtonGrid {
  margin-right: 38px;
  margin-bottom: 36px;
}

.validationButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
  background-color: #4494d0;
}

.cancelButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
}

.selectProxy {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  border: 1px solid #394e74;
  text-decoration: none;
  border-bottom: none !important;
  border-bottom-color: #394e74;
  padding-left: 13px !important;
}

.uploadFieldset {
  overflow: hidden
}

.uploadInput {
  float: left;
  clear: none;
  display: flex;
}

.uploadRadio {
  float: left;
  clear: none;
  margin: 2px 0 0 2px;
  max-width: 13px;
}

.uploadLabel {
  float: left;
  clear: none;
  display: block;
  padding: 0px 1em 0px 8px;
  max-width: 90%;
  font-size: small;
}

.hidden {
  display: none;
  pointer-events: none;
}