@import "../manage-notifications.module";

.mark_as_read_button {
    &__action_icon {
        width: 24px;
        height: 24px;
        color: var(--lightBlueColor);
    }

    &__loader_icon {
        height: 24px;
        width: 48px;
    }
}
