.appBar {
  width: calc(100% - var(--drawerWidth));
  margin-left: var(--drawerWidth);
  background-color: var(--whiteColor);
  color: var(--darkGrayColor);
  box-shadow: none;
}

.link {
  &:hover {
    color: var(--whiteColor);
  }

  text-decoration: none;
}

.mainContent {
  flex-grow: 1;
}

.container {
  height: 80px;
}

.emptyAvatar {
  font-size: 48px;
  color: var(--darkGrayColor);
}

.filledAvatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.headerMenuButton {
  margin-right: 10px;
}

.button {
  color: var(--darkRedColor);
  border: 2px solid var(--darkRedColor);
  border-radius: 19px;

  &:hover {
    background-color: var(--darkRedColor);
    color: var(--whiteColor);
  }
}

.textCost {
  color: var(--darkBlueColor);
  height: 14px;
  display: block;
  font-family: var(--fontFamily);
  font-size: 12px;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  padding-right: 4px;
  padding-left: 4px;
  text-transform: none;
}

.textWelcome {
  color: var(--darkBlueColor);
  height: 16px;
  display: block;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  padding-right: 4px;
  padding-left: 4px;
  text-transform: none;
}

.igg {
  display: block;
  height: 14px;
  font-size: 14px;
  font-family: var(--fontFamily);
  color: var(--darkGrayColor);
  padding-right: 4px;
  padding-left: 4px;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  text-transform: none;
}
.boldText {
  color: var(--darkBlackColor);
}

.menuText {
  color: var(--darkBlackColor);
  height: 19px;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  padding-right: 15px;
  padding-left: 15px;
}

.profileLink {
  color: currentColor;
  text-decoration: none;
}

.carbonButton {
  color: rgba(0, 170, 40, 1);
  background-color: white;
  height: 60px;
}

.textCarbon {
  color: rgba(0, 170, 40, 1);
  height: 19px;
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: bold;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  padding-right: 4px;
  padding-left: 4px;
}