@import "../../styles/styles";

.loader{
  animation: rotate 2s linear infinite;
  width: 64px;
  height: 64px;
  object-fit: contain;
}

@keyframes rotate{
  100%{
    transform: rotate(360deg);
  }
}