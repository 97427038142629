@import '../../styles/styles';

.zone_info_container {
  margin-left: 70px;
  margin-right: 20px;

  &__title {
    color: var(--darkRedColor);
    height: 28px;
    font-size: 24px;
    font-style: normal;
    margin-top: 41px;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  &__zone_info_loader_container {
    margin-top: 100px;
    text-align: center;
  }

  &__zone_info {
    margin-top: 20px;
    padding: 38px 48px;
    background: var(--whiteColor);
    border-radius: 3px;
    box-shadow: -3px 2px 2px 0px rgba(119, 151, 178, 0.16);

    &__header {
      background: var(--darkBlueColor);
      padding-left: 24px;
      height: 37.9px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__header_text {
        color: rgb(255, 255, 255);
        font-weight: 500;
        font-size: 16px;
        height: 19px;
        letter-spacing: 0px;
      }
    }

    &__content {
      margin-top: 20px;
      padding: 0px;

      &__section_items {
        display: flex;
        font-size: 14px;

        &__item {
          display: grid;
          row-gap: 1em;
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          font-size: 14px;
        }
      }
    }

    &__section_label {
      color: #394e74;
      font-size: 16px;
      font-weight: 500;
      height: 19px;
      letter-spacing: 0px;
    }

    &__section {
      margin-top: 24px;
    }

    &__informations_label {
      color: rgb(42, 59, 90);
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      text-align: left;
    }

    &__value {
      color: rgb(74, 74, 74);
      font-size: 18px;
      font-weight: 500;
      margin-top: -5px;
    }

    &__managers {
      margin-top: 24px;
    }
  }

  &__loader_container {
    margin-top: 300px;
    text-align: center;
  }
}
