@import "../../../styles/styles";

.site-information-container {
  margin-left: 70px;
  margin-right: 20px;

  & .loadingSpinner {
    margin-top: 100px;
    text-align: center;
  }

  & .section-title-site {
    color: var(--darkRedColor);
    height: 28px;
    font-size: 24px;
    font-style: normal;
    margin-top: 41px;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  & .menu-button-mail {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: var(--darkSkyBlue);
    margin-top: 20px;

    &:hover {
      background: #0f7ac1;
    }
  }

  & .menu-button-text-mail {
    text-transform: none;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: normal;
    color: white;
  }

  & .site-container {
    margin-top: 20px;
    padding: 38px 48px;
    background: var(--whiteColor);
    border-radius: 3px;
    box-shadow: -3px 2px 2px 0px rgba(119, 151, 178, 0.16);

    & .top-banner-site__panel {
      background: var(--darkBlueColor);
      padding-left: 24px;
      height: 37.9px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .top-banner-site__title {
        color: rgb(255, 255, 255);
        font-weight: 500;
        font-size: 16px;
        height: 19px;
        letter-spacing: 0px;
      }
    }

    & .site-detail-block {
      margin-top: 20px;

      & .section-items {
        display: grid;
        row-gap: 1em;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        font-size: 14px;

        & .item {
          & .label-item {
            color: var(--lightBlueColor);
            font-size: 16px;
            font-weight: 500;
            height: 19px;
            letter-spacing: 0px;
          }

          & .value-item {
            color: rgb(74, 74, 74);
            font-size: 20px;
            font-weight: 500;
            height: 24px;
          }
        }
      }
    }

    & .site-desciption-block {
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: 0px;
      margin-top: 20px;

      & .site-desciption-block__title {
        color: var(--darkBlueColor);
        font-size: 16px;
        font-weight: 500;
        height: 19px;
        padding-top: 10px;
      }

      & .site-desciption-block__content {
        color: var(--darkBlueColor);
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0px;
        text-align: justify;
      }
    }

    & .site-managers-block {
      margin-top: 20px;

      & .list-item-even {
        background-color: var(--lightGrayColor);
      }

      & .site-managers-block__title {
        color: var(--darkBlueColor);
        font-size: 16px;
        font-weight: 500;
        height: 19px;
        letter-spacing: 0px;
      }

      & .site-managers-block_content {
        margin-top: 10px;
        max-height: 235px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 0px;
        border: 1px solid rgb(245, 245, 245);

        & .site-managers-block_content__userFilledAvatar {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }

        & .site-managers-block_content__userEmptyAvatar {
          font-size: 48px;
          color: var(--darkGrayColor);
        }
      }
    }
  }
}