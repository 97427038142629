.menu_item {
    padding: 0%;
  }

.subMenuItem {
    display: 'flex';
    justify-content: 'space-between';
    padding: 0%;
  }

.arrow_right_icon {
  padding-top: 6px;
  padding-bottom: 6px;
}

.menu_item_content {
  width: 100%;
  padding: 6px 16px 6px 16px;
}
