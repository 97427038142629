@import "../header/header-styles.module";

.helpButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 60px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--darkBlackColor);
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  padding-right: 4px;
  padding-left: 4px;

  &:hover {
    cursor: pointer;
  }
}

.menuItemButton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
}

.menuItemButtonText {
  text-transform: none;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 16px;
  font-weight: normal;
  color: grey;
}

.text {
  padding-left: 5px;
  color: grey
}