@import "../../styles/styles";

.project-manager-list-root {
  margin-top: 15px;

  .label-bold {
    font-weight: 500;
  }
}

.project-manager-list-container {
  margin-top: 7px;
  border-radius: 2px;

  & .loader-icon {
    height: 40px;
    width: 40px;
  }

  & .project-manager-list {
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    
    & .project-managers-block_content__userFilledAvatar {
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }

    & .list-item-odd {
      background-color: var(--lightGrayColor);
    }

    & .project-manager-avatar {
      margin-top: 5px;
      font-size: 38px;
    }
  }
}
