@import "../../../styles/styles";

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  min-width: 260px;
  width: 100%;
}
.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 1.5rem;
  padding: .5rem 0.5rem;
  line-height: 1.8;
  color: #afafaf;
  background-color: #fff;
  border: .05rem solid #293b5a;
  border-radius: .15rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0,0,0,.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.file-custom:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.file-custom:before {
  position: absolute;
  top: -.075rem;
  right: -.075rem;
  bottom: -.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 2.5rem;
  padding: .5rem 1.2rem;
  line-height: 1.8;
  color: #FFF;
  background-color: #293b5a;
  border: .075rem solid #293b5a;
  border-radius: 0 .15rem .15rem 0;
}