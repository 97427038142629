@import "../../styles/styles";

.inputRoot {
  flex: 1;
  min-width: 50px;
  margin-top: 0;
  margin-left: 5px;
}

.input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  appearance: none;
  float: left;
  height: 50px;
}

.chipContainer {
  cursor: text;
  margin-bottom: -2;
  min-height: 40px;
}

.label {
  top: 4;
}

.labelShrink {
  top: 0;
}

.helperText {
  margin-bottom: -20;
}

.inkbar {
  display: flex;
  flex-wrap: wrap;

  &:after {
    left: 0;
    bottom: 0;
    content: '';
    height: 2px;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    // TODO
    // transition: theme.transitions.create('transform'; {
    //   duration: theme.transitions.duration.shorter;
    //   easing: theme.transitions.easing.easeOut;
    // });
    pointer-events: none;
  }

  //TODO
  // &$focused:after {
  //   transform: 'scaleX(1)';
  // }
  border: 1px solid #394e74;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.chip {
  margin: 10px 8px 8px 0;
  float: left;
  background-color: var(--lightBlueColor);
  color: var(--whiteColor);
  border-radius: 2px;

  &:hover {
    background-color: var(--darkBlueColor);
  }
}

.icon {
  color: var(--whiteColor);
}

.errorMessage {
  color: var(--darkRedColor);
  height: 19px;
  font-size: 16px;
  font-style: var(--normalFontStyle);
  margin-top: 7px;
  font-family: var(--fontFamily);
  font-weight: 500;
  line-height: var(--normalLineHeight);
  font-stretch: var(--normalFontStretch);
  letter-spacing: var(--normalLetterSpacing);
  margin-left: 21px;
}