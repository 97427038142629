@import "../../styles/styles";

.welcome-message {
  margin-left: 70px;

  & .title {
    text-transform: uppercase;
  }

  & .message {
    font-weight: normal;
    color: var(--darkGrayColor);
  }

  & .link-style {
    color: var(--darkGrayColor);
    font-weight: bold;
  }
}
