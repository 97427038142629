@import "../../../styles/mixins.scss";

.vmComponentGrid {
  margin-right: 12px;
  position: relative;
  height: 100%;
}

.icon {
  margin: 8px;
  font-size: 32px;
}

.deleteIconButton {
  position: absolute;
  padding: 0px;
  right: -3px;
  top: 4px;
  background: white;

  &:hover {
    background: white;
  }
}

.deleteSessionIcon {
  font-size: 30px;
  color: var(--darkBlackColor);
}

.calenderIcon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.sessionLaunchIcon {
  color: var(--whiteColor);
  width: 72px;
  height: 72px;
  object-fit: contain;
}

.paper {
  width: 340px;
  height: 250px;
  margin-top: 19px;
  padding: 8px;
  text-align: var(--textCenterAlignment);
  color: var(--whiteColor);
  border-radius: 0px !important;
}

.projectScreenShot {
  width: 100%;
  height: 170px;
  border-radius: 0px;
  box-shadow: 0 15px 38px 0 rgba(119, 151, 178, 0.16);
  background-color: var(--darkGrayColor);
  margin-top: 8px;
  margin-left: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
}

.projectPendingScreenShot {
  opacity: 0.9;
  background-color: var(--darkBlackColor);
}

.projectPendingLoader {
  margin-top: 31px;
}

.instanceName {
  user-select: all;
  height: 22px;
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  text-align: var(--textCenterAlignment);
  color: var(--lightBlackColor);
  margin-bottom: 7px;
}

.homeDirFull {
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: 100;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  text-align: var(--textCenterAlignment);
  color: var(--lightBlackColor);
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
}

.unselectable {
  @include unselectable;
}

.siteZoneProjectName {
  height: 22px;
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  text-align: var(--textCenterAlignment);
  color: var(--lightBlackColor);
}

.tooltipPlacementBottom {
  margin: 0px 0;
}

.projectDetailsContainer {
  display: flex;
}

.projectDetails {
  flex: 1;
  height: auto;
}

.projectStatusLabel {
  font-family: var(--fontFamily);
  font-size: 16px;
  color: var(--darkWhiteColor);
  margin-top: 12px;
}

.projectStatus {
  font-weight: 500;
}

.ownerName {
  font-family: var(--fontFamily);
  font-size: 10px;
  color: var(--darkGrayColor);
}

.creationDate {
  font-family: var(--fontFamily);
  font-size: 10px;
  font-weight: var(--normalFontWeight);
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  text-align: var(--textCenterAlignment);
  color: var(--darkGrayColor);
}

.sharingButton {
  position: absolute;
  right: 8px;
  bottom: 0px;
}

.runningContainer {
  font-size: 14px;
  height: 100%;
  color: var(--whiteColor);
}

.statusRunningContainer {
  margin-top: 18px;

  & span {
    color: var(--grayColor);
  }
}

.costContainer {
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.costContainer2 {
  position: absolute;
  top: 155px;
  left: 115px;
  font-size: 10px;
  color: var(--whiteColor);
}

.latencyRunningContainer {
  & span {
    color: var(--grayColor);
  }
}

.openVmUrlButton {
  margin: 5px;
  margin-top: 7px;
  padding-left: 5px;

  & span {
    margin-left: 5px;
  }

  background: #1491ef;
  border-radius: 2px;
  height: 46px;
  width: 108px;
}

.downloadDcvFileButton {
  margin: 5px;
  margin-top: 7px;
  padding-left: 5px;

  & span {
    margin-left: 5px;
  }

  background: #3862ad;
  border-radius: 2px;
  height: 46px;
  width: 108px;
}

.addScheduleContainer {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countdown {
  margin: 0 10px 0 5px;
}

.countdownRed {
  margin: 0 10px 0 5px;
  color: var(--redColor);
}

.scheduleIcon {
  width: 15px;
  color: var(--grayColor);
}

.scheduleIconRed {
  width: 15px;
  color: var(--redColor);
}

.resolutionContainer {
  left: 320px;
  top: 136px;
  width: 28px;
  position: absolute;
}

.resolutionIcon {
  width: 28px;
  height: 28px;
  color: var(--grayColor);
  margin: 0 auto;
}

.vmActionContainer {
  bottom: 0px;
  position: absolute;
}

.sudoContainer {
  left: 8px;
  top: 225px;
  width: 28px;
  height: 28px;
  position: absolute;
}

.connectivityIcon {
  width: 28px;
  height: 28px;
  left: 320px;
  top: 24px;
  color: var(--grayColor);
  position: absolute;
}

.connectivityBlinkIcon {
  width: 28px;
  height: 28px;
  left: 320px;
  top: 24px;
  color: var(--grayColor);
  position: absolute;
  animation: blink50 1.5s linear infinite;
}

.connectivityRedIcon {
  width: 28px;
  height: 28px;
  left: 320px;
  top: 24px;
  color: var(--redColor);
  position: absolute;
  animation: blink50 1.5s linear infinite;
}

.cpuProgressLine {
  height: 3px;
  width: 24px;
  left: 322px;
  top: 54px;
  position: absolute;
}

.addScheduleButton {
  border-color: var(--whiteColor);
  padding: 4px 10px;
  width: 40px;
  min-width: 40px;
  min-height: 0px;
}

.loaderWaitingExpandingLifeTime {
  height: 20px;
}

.fullWidthContainer {
  width: 100%;
  margin-top: -8px;
}

.shareInstanceSharedWithMe {
  font-size: 1.5rem;
}

.shareInstanceSharedWithMeDisabled {
  opacity: 0.6;
  color: #3862ad;
}

.expandIconMore {
  border-color: var(--whiteColor);
  color: var(--whiteColor);
  padding: 2px 8px;
  width: 10px;
  min-width: 10px;
  min-height: 0px;
}

.labelWithTooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;

  & span {
    margin-right: 10px;
  }
}

.vmEventWithTooltip {
  margin-bottom: -15px;
}