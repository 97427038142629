@import "../../styles/styles";

.title {
  @include titre;
}
.loaderContainer {
  margin-top: 100px;
  text-align: center;
}
.container {
  margin-top: 16px;
  margin-left: 70px;
  display: flex;
  flex-wrap: wrap;
}
