@import "../../styles/styles";

.appContent {
  display: flex;
  min-height: 100vh;
  background: var(--lightGrayColor);
  overflow-x: auto;
  flex: 1;
}

.mainContent {
  flex: 1;
  padding-top: 70px;
  min-height: calc(100vh - 70px);
}
