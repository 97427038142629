@import "../../styles/styles";

.site-cost {
  width: 386px;
  margin: 15px 15px 15px 0;

  .header {
    background-color: #2a3b5a;
    color: var(--whiteColor);
    padding: 10px 0 5px 10px;
    font-size: 18px;
    display: flex;
    justify-content: center;

    & > :first-child {
      flex: auto;
      align-items: center;
    }

    & > div {
      display: flex;
    }

    .running-vm {
      margin-left: 8px;
      font-size: 14px;
      align-items: center;
    }

    .edit-icon-site {
      color: var(--whiteColor);
      height: 24px;
    }

  }

  .delete-icon {
    color: var(--whiteColor);
    height: 18px;
  }

  .content {
    background-color: white;
    border: 1px solid rgb(182, 190, 205);

    .section-items {
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 10px;

      .item {
        max-width: 150px;
      }
    }
        
    .list-section-items {
      display: block;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 10px;
        
      .machine-category-label {
        margin-top: auto;
        margin-bottom: auto;
        width: 35%;
        font-size: 12px;
      }
        
      .list-item {
        display: flex;
        width: 100%;
      }

      .value {
        font-size: 15px;
        width: 25%;
        text-align: right;
      }
    }
  }

  .section-label {
    color: #394e74;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  .section {
    margin-top: 24px;
  }

  .machine-category-label {
    color: #4a4a4a;
    margin-bottom: 1px;
    font-size: 14px;
  }

  .value {
    font-size: 20px;
    font-weight: bold;
    color: #4a4a4a;    
  }

  .valueIcon {
    vertical-align: text-bottom;
  }
.last-update {
  margin-top: 24px;
  font-size: 15px;
}
}
