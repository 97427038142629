@import "../../styles/styles";
@import '../../components/shared/shared';

.project-download {
  margin-left: 70px;
  margin-right: 20px;

  & .container {
    background-color: white;
    margin-top: 20px;
    padding: 38px 48px;
    min-width: 600px;

    & .title-filled {
      background: var(--darkBlueColor);
      height: 37.9px;
      padding-left: 24px;
      font-size: 16px;
      font-weight: 500;
      color: white;
      display: flex;
      align-items: center;
    }

    & .description {
      margin: 20px 0;
      font-size: 14px;
      color: rgb(74, 74, 74);
    }

    & .menu-download {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & .files-container {
      & .files-title {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        color: rgb(42, 59, 90);
      }

      & .waiting-for-files-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        & .loader {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      .file-list {
        margin-top: 10px;
        border: 1px solid rgb(216, 216, 216);
      }

      .list-item {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 24px;
      }



      .file-name {
        color: var(--lightBlackColor);
        font-size: 16px;
        font-weight: 500;
      }

      .delete-date {
        color: var(--lightBlackColor);
        font-size: 14px;
        display: flex;
        padding-left: 10px;
      }

      .creation-date {
        color: var(--lightBlackColor);
        font-size: 14px;
        display: flex;
        padding-left: 10px;
      }

      .file-size {
        color: var(--lightBlackColor);
        font-size: 14px;
        display: flex;
        padding-left: 10px;
      }

      .action-buttons {
        display: flex;
        flex-direction: row;
        size: 20px;
      }

      .fileshare-button {
        margin-left: 20px;
      }


      .download-icon-reversed {
        transform: rotate(180deg);
      }
    }
  }
}

.validationButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
  background-color: #4494d0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
