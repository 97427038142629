@import "../../../styles/styles";

.icon {
  width: 24px;
  height: 24px;
  color: #3d4657;
  margin-right: 12px;
}
.customRunTitle {
  width: auto;
  height: 22px;
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  color: var(--darkBlueColor);
}
.label {
  width: auto;
  height: 22px;
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 500;
  color: var(--darkBlueColor);
}
.accessAttributionButtonGrid {
  margin-bottom: 36px;
}
.validationButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
  background-color: #4494d0;
}
.cancelButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
}
