@import "../../styles/styles";

.project-info-container {
  margin-left: 70px;
  margin-right: 20px;

  .project-info-loader-container {
    margin-top: 100px;
    text-align: center;
  }

  .project-info {
    margin-top: 20px;
    padding: 38px 48px;
    background: var(--whiteColor);
    border-radius: 3px;
    box-shadow: -3px 2px 2px 0px rgba(119, 151, 178, 0.16);

    .header {
      background-color: var(--darkBlueColor);
      padding-left: 24px;
      height: 19px;
      display: flex;
      align-items: center;

      .header-text {
        color: rgb(255, 255, 255);
        font-weight: 500;
        font-size: 16px;
        height: 19px;
        letter-spacing: 0px;
      }

      &>div {
        display: flex;
      }
    }

    .content {
      padding: 0px;
      margin-top: 15px;

      .section-items {
        display: flex;
        font-size: 14px;


        .item {
          margin-right: 70px;
        }
      }
    }

    .section-label {
      color: #394e74;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
    }

    .section {
      margin-top: 24px;
    }

    .informations-label {
      color: rgb(42, 59, 90);
      font-size: 16px;
      letter-spacing: 0px;
      text-align: left;
    }

    .value {
      color: rgb(74, 74, 74);
      font-size: 18px;
      margin-top: 10px;
    }

    .project-managers {
      margin-top: 24px;
    }
  }

  .loaderContainer {
    margin-top: 300px;
    text-align: center;
  }
}