@import "../../styles/styles";

.version-selection {
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
}

.version-selection__title {
  color: var(--darkBlueColor);
  font-size: 16px;
  font-weight: 500;
  height: 19px;
  letter-spacing: 0px;
  margin-right: 20px;
}

.inputFieldLabel {
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  color: var(--grayColor);
  margin-top: -7px;
  border-bottom: none;
}

.edit-button-color {
  color: var(--whiteColor) !important;
}

.labelWithTooltip {
  display: flex;

  & .span {
    margin-right: 10px;
  }
}

.inputField {
  width: 193px;
  height: 54px;
  border-radius: 5px;
  border: 1px solid var(--grayColor);
  text-decoration: none;
  border-bottom: none !important;
  padding-left: 13px !important;
}

.second-input-block-info-site {
  margin-top: 15px;
  justify-content: space-between;
}

.second-input-block-info-site__switcher {
  & .switcher-label {
    color: rgb(74, 74, 74);
    font-size: 16px;
    font-weight: 500;
    height: 19px;
    letter-spacing: 0px;
    text-align: center;
    width: 113px;
    margin-left: -16px;
  }

  margin-top: 32px;
}

.thumb-color {
  background-color: rgb(19, 122, 123) !important;
}

.slider-color {
  background-color: rgb(173, 221, 221) !important;
}

.slider-color:checked {
  background-color: #ab496c !important;
}

.edit-modal-site-name {
  color: var(--darkGrayColor);
  font-size: 16px;
  font-weight: 500;
  height: 19px;
  letter-spacing: 0px;
  text-align: center;
}

.incr-decr-button {
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
  height: 32px;

  & .incr-decr-button_left_label {
    color: var(--lightBlackColor);
    font-size: 16px;
    font-weight: 500;
    height: 38px;
    letter-spacing: 0px;
  }

  & .incr-decr-button_right_label {
    color: var(--lightBlackColor);
    font-size: 16px;
    font-weight: 500;
    height: 38px;
    letter-spacing: 0px;
    width: 93px;
  }

  & .value-button {
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #ddd;
    height: 32px;
    width: 32px;
    text-align: center;
    vertical-align: middle;
    background-color: var(--sexyblue);
    color: #ffffff;
    user-select: none;
  }

  & .value-button:hover {
    cursor: pointer;
  }

  #decrease {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 3px;
    margin-left: 24px;
  }

  #increase {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border-radius: 3px;
    margin-right: 24px;
  }

  input#number {
    text-align: center;
    border-radius: 2px;
    border: 1px solid rgb(57, 78, 116);
    height: 32px;
    width: 40px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}