@import "../../../styles/styles";

@keyframes blink50 {
  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.dialog-container {
  overflow: visible !important;
  width: 650px;

  .invite-users-label {
    margin-bottom: 7px;
    font-weight: 500;
  }

  .invite-users-info {
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 13px;
  }
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.instancesharing-name {
  margin-top: 4px;
  text-align: center;
  color: var(--darkGrayColor);
  font-size: 16px;
  font-weight: 500;
  height: 19px;
  letter-spacing: 0px;
}

.instancesharing__icons {
  & .instancesharing__alreadyShared {
    font-size: 1.5rem;
    color: rgb(56, 98, 173);
  }

  & .instancesharing__owned {
    font-size: 1.5rem;
  }
}

.loaderUpdate {
  width: 20px;
  height: 20px;
}