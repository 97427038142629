@import "../../styles/styles";

.manage_notification {
  &__tableHeadRow {
    width: auto;
    height: 17px;
    text-align: left;
    font-family: var(--fontFamily);
    font-size: 14px;
    font-weight: 500;
    font-style: var(--normalFontStyle);
    font-stretch: var(--normalFontStretch);
    line-height: var(--normalLineHeight);
    letter-spacing: var(--normalLetterSpacing);
    color: var(--darkBlueColor);
    margin-left: 31px;
  }

  &__tableContainer {
    margin-top: 20px;
    min-width: 910px;
    width: 100%;
  }

  &__actionsTableHeadRow {
    margin-left: 0px;
    width: auto;
    height: 17px;
    text-align: left;
    font-family: var(--fontFamily);
    font-size: 14px;
    font-weight: 500;
    font-style: var(--normalFontStyle);
    font-stretch: var(--normalFontStretch);
    line-height: var(--normalLineHeight);
    letter-spacing: var(--normalLetterSpacing);
    color: var(--darkBlueColor);
  }

  &__tableRow {
    width: auto;
    height: 27px;
    text-align: left;
    color: var(--lightBlackColor);
  }

  &__darkTableRow {
    background-color: var(--lightGrayColor);
    border-color: transparent;
  }

  &__title {
    font-size: 26px;
    color: var(--darkSkyBlue);
    font-weight: 500;
  }

  &__sortIcon {
    height: 10px;
    color: var(--darkGrayColor);
  }

  &__sortIconActive {
    height: 20px;
    color: var(--darkSkyBlue);
  }

  &__columnTitle {
    white-space: nowrap;
  }

  &__topContainer {
    margin-left: 20px;
  }

  &__loadingSpinner {
    margin-top: 100px;
    text-align: center;
    overflow: hidden;
  }

  &__actionIcon {
    width: 24px;
    height: 24px;
    color: var(--lightBlueColor);
    border: 1px solid var(--lightBlueColor);
  }

  &__toolBarPaginationElement {
    position: absolute;
    right: 0px;
    padding-right: 0px !important;
  }

  &__pagination {
    text-align: right;
  }

  &__paginationFooter {
    float: right;
    padding: 10px;
  }

  &__tooltipPlacementBottom {
    margin: 0px 0;
  }

  &__unread td {
    font-weight: bold;
  }

  &__toolbarIcon:hover {
    color: #3f51b5;
  }

  &__severityColumn {
    width: 100px;
  }

  &__dateColumn {
    width: 100px;
  }

  &__actionColumn {
    width: 100px;
  }

  &__actionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__customFilterFooter {
    min-width: 400px;
  }

}