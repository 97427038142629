@import "../../../styles/styles";

.menuButton {
  border-color: var(--darkBlackColor);
  padding: 4px 4px;
  width: 28px;
  min-width: 28px;
  min-height: 0px;
  border-width: 0px;
}

.menuIcon {
  width: 20px;
  height: 20px;
  color: var(--darkGrayColor);
  margin: 0 auto;
}

.loaderWaitingSudo {
  width: 20px;
  height: 20px;
}

.sudoIcon {
  width: 20px;
  height: 20px;
  color: var(--darkGrayColor);
  padding-right: 4px;
}

.smgIcon {
  width: 20px;
  height: 20px;
  padding-right: 4px;
}

.menuItem {
  justify-content: left;
  align-items: end;
  font-size: 16px;
}
