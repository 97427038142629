@import "../../styles/styles";

.section-title {
  margin-left: 70px;
  margin-right: 20px;
  margin-bottom: 70px;

  color: var(--darkRedColor);
  height: 28px;
  font-size: 24px;
  font-style: normal;
  margin-top: 41px;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
