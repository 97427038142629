@import "../shared/menu/menu-item.module.scss";

.create_resource {

  &__input_field {
    width: 100%;
    height: 44px;
    border-radius: 2px;
    border: 1px solid #394e74;
    text-decoration: none;
    border-bottom: none !important;
    border-bottom-color: #394e74;
    padding-left: 13px !important;
  }
  
  &__input_resource_name {
    width: 100%;
    height: 44px;
    border-radius: 2px;
    border: 1px solid #394e74;
    text-decoration: none;
    border-bottom-color: #394e74;
    padding-left: 13px !important;
  }

  &__input_field_label {
    transform: none !important;
    height: 19px;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #9b9b9b;
    border-bottom: none !important;
    line-height: 1;
  }
  
  &__resource_text_field_container {
    margin-top: 30px;
    margin-bottom: 20px;
    
    &__resource_text_field {
      padding-top: 25px;
      width: 100%;
    }
  }

  &__error_message {
    color: var(--darkRedColor);
    font-size: 12px;
  }

  &__slider_and_input {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__slider {
    width: 80%;
  }
  
  &__slider_root {
    margin-top: 10px;
    width: 100%;
    padding: 0;
    overflow: visible;

    &__fileshare_size {
      margin-top: 5px;
      font-size: 14px;
    }
  }

  &__filestore_price {
    font-size: 14px;
    width: 100%;
    margin: 20px 0 20px 0;
    text-align: right;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
}
