.item-capability {
    padding-inline: 15px;
    padding-top: 30px;
    user-select: none;
}

.value-item-capability {
    font-size: 15px;
    font-weight: 400;
    height: 15px;
    text-align: center;
    user-select: none;
}

.label-item-capability {
    font-size: 20px;
    font-weight: 500;
    height: 15px;
    letter-spacing: 0px;
    padding-bottom: 10px;
    text-align: center;
    user-select: none;
}

.icon-item-capability {
    height: auto;
    margin: 0 auto;
    padding: 0;
    position: relative;
    content: justify-content;
    user-select: none;
}

.site-capability-block_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px;
    user-select: none;
}

.site-capability-block_content__capability-icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    user-select: none;
}

.site-capability-block__title {
    font-size: 16px;
    color: var(--darkBlueColor);
    font-weight: 500;
    height: 19px;
    letter-spacing: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.site-capability-block {
    margin-top: 20px;
}

.nocapabilitysite {
    color: var(--darkBlueColor);
    font-size: 16px;
    font-weight: 500;
    height: 19px;
    letter-spacing: 0px;
    padding: 20px;
    text-align: center;
    user-select: none;
    padding-top: 40px;
}