.dialog {
  .dialogTitle {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      color: var(--darkBlueColor);
      margin-right: 12px;
    }

    .modalTitle {
      width: auto;
      height: 22px;
      font-size: 18px;
      font-weight: 500;
      color: var(--darkBlueColor);
    }
  }

  .dialogContent {
    overflow: visible;
    min-width: 500px;
  }

  .dialog-actions {
    margin: 15px 16px;

    .validation-button {
      width: 150px;
      height: 51px;
      border-radius: 3px;

      &:enabled {
        background-color: var(--darkSkyBlue);

        &:hover {
          background-color: var(--darkBlueColor);
        }
      }
    }

    .cancel-button {
      width: 150px;
      height: 51px;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--darkSkyBlue);
}

.MuiCheckbox-colorSecondary.Mui-disabled {
  color: var(--grayColor);
}

.MuiTabs-indicator {
  background-color: var(--darkSkyBlue);
}

.justify-center {
  display: flex;
  justify-items: center;
}

.center-content {
  text-align: center;
}

.title {
  width: auto;
  height: 28px;
  font-size: 24px;
  font-weight: 500;
  color: var(--darkRedColor);
  margin-top: 41px;
}

.dialogSubTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}