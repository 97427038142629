/* COLORS */

:root {
  --lightGrayColor: #f5f6fa;
  --grayColor: #aaaaaa;
  --darkGrayColor: #6a6c71;
  --whiteColor: #ffffff;
  --darkWhiteColor: #d0d4da;
  --lightBlackColor: #4a4a4a;
  --darkBlackColor: #333333;
  --lightBlueColor: #394e74;
  --darkBlueColor: #2a3b5a;
  --darkRedColor: #99213b;
  --redColor: red;
  --darkSkyBlue: #4494d0;
  --sexypurple: #86509b;
  --orangeColor: #ffa500;
  --sexyblue: #3862ad;

  /* FONT STYLES */
  --font-family: Roboto;
  --normalFontStyle: normal;
  --normalFontStretch: normal;
  --normalFontWeight: normal;
  --normalLineHeight: normal;
  --normalLetterSpacing: normal;
  /* TEXT STYLES */
  --textCenterAlignment: center;
  --textLeftAlignment: left;

  --drawerWidth: 240px;
}