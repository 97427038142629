@import "../../styles/styles";

.user-profile-container {
  margin-left: 70px;
  margin-right: 20px;

  & .user-profile-title {
    color: #99213b;
    width: auto;
    height: 28px;
    font-size: 24px;
    font-style: normal;
    margin-top: 41px;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  & .top-banner {
    background: rgb(56, 98, 173);
    border-radius: 0px;
    margin-top: 24px;
    height: 227px;
  }

  & .profile-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & .user-avatar {
      background-color: #f5f6fa;
      pointer-events: auto;
      position: relative;
      width: 124px;
      height: 124px;
      border-radius: 50%;

      input[type='file'] {
        cursor: pointer;
        position: relative;
        height: 124px;
        width: 124px;
        color: transparent;
        opacity: 0;
      }
    }

    & .empty-user-avatar {
      width: 0.66em;
      height: 0.66em;
      margin-left: -13px;
      margin-top: -13px;
      position: absolute;
      font-size: 228px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }

    & .user-avatar>img {
      position: absolute;
      height: 124px;
      width: 124px;
      border-radius: 50%;
    }

    & .user-avatar:hover>img {
      position: absolute;
      display: none;
    }

    & .user-avatar:hover .empty-user-avatar {
      position: absolute;
      background-color: transparent;
    }

    & .user-avatar:hover {
      background: rgb(37, 38, 39);
      height: 124px;
      width: 124px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }

    & .user-avatar:hover .change-avatar-text {
      color: rgb(255, 255, 255);
      font-size: 12px;
      font-weight: normal;
      height: 28px;
      text-align: center;
      width: 68px;
      left: 25%;
      top: 50%;
      position: relative;
      display: inline-flex;
    }

    & .user-avatar:hover .change-avatar-icon-edit {
      color: rgb(255, 255, 255);
      font-weight: 500;
      font-size: 12px;
      height: 28px;
      text-align: center;
      width: 68px;
      left: 27%;
      top: 50%;
      position: relative;
      display: inline-flex;
    }

    & .change-avatar-text {
      display: none;
    }

    & .change-avatar-icon-edit {
      display: none;
    }

    & .user-info {
      color: rgb(255, 255, 255);
      font-size: 20px;
      font-weight: 500;
      height: 24px;
      text-align: justify;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;

      & .user-mail {
        color: rgb(185, 199, 224);
        font-size: 16px;
        font-weight: normal;
        height: 19px;
      }
    }
  }
}

.home-directory-container {
  display: flex;
  justify-content: center;
  align-items: center;

  & .home-directory-infos {
    margin-right: 40px;
  }
}

.home-directory-download-button {
  width: 150px;
  height: 51px;
  border-radius: 3px;
  background-color: var(--sexyblue);
}

.home-directory-delete-button {
  width: 150px;
  height: 51px;
  border-radius: 3px;
  background-color: var(--redColor);
}

.download_icon_reversed {
  transform: rotate(180deg);
}