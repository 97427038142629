@import "../manage-notifications.module";

.delete_button {
    &__action_icon {
        width: 24px;
        height: 24px;
        color: var(--lightBlueColor);
    }

    &__delete_loader_icon {
        height: 24px;
        width: 48px;
    }
}
