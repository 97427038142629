@import "../../../styles/styles";

.resizable-div-root {
  display: flex;
}

.container {
  overflow: auto;
}

.resizer {
  background-color: darkgrey;
  width: 3px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none;   /* Firefox all */
  -ms-user-select: none;  /* IE 10+ */
  user-select: none;  /* Likely future */
}

.resizer:hover {
  cursor: col-resize;
}