@import "../../styles/styles";

.file-upload-component {
  width: 400px;
  font-family: 'Roboto', sans-serif;

  .header {
    background-color: #2a3a5b;
    padding: 10px 0;
    color: white;
    text-transform: uppercase;
    text-align: center;
    border-radius: 3px 3px 0 0;

    vertical-align: middle;

    .close-container {
      float: right;
      margin-top: -4px;
      margin-right: 4px;
      .icon-close {
        padding: 0;
        color: white;
      }
    }
  }

  .body {
    padding: 0 10px;
    background-color: white;
    color: #575757;

    .file-name-container {
      display: flex;
      align-items: center;
      padding: 5px 0;
    }

    .file-icon {
      color: #384d75;
      margin-right: 7px;
    }

    .file-name {
      max-width: 230px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .file-size {
      color: #b3b3b3;
    }

    .action-icon {
      flex: 1;
      text-align: right;

      .icon-cancel, .icon-failed, .icon-cancelled {
        color: #a4344c;
      }

      .icon-completed {
        color: #53b056;
      }
    }

    .progress-info {
      padding: 7px 0;

      .upload-error-msg, .upload-completed {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon-error {
          color: #9b1f39;
        }
      }
    }
  }
}