@import "../../../styles/styles";

.dialogContainer {
  overflow: visible;
}
.dialogTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogContent {
  overflow: visible;
  min-width: 500px;
}
.icon {
  width: 24px;
  height: 24px;
  color: #3d4657;
  margin-right: 12px;
}
.customRunTitle {
  width: auto;
  height: 22px;
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  color: var(--darkBlueColor);
}
.usersSelectorContainer {
  margin-top: 43px;
}
.inputField {
  width: 217px;
  height: 44px;
  border-radius: 2px;
  border: 1px solid #394e74;
  text-decoration: none;
  border-bottom: none !important;
  border-bottom-color: #394e74;
  padding-left: 13px !important;
}
.inputFieldLabel {
  height: 19px;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: 500;
  font-style: var(--normalFontStyle);
  font-stretch: var(--normalFontStretch);
  line-height: var(--normalLineHeight);
  letter-spacing: var(--normalLetterSpacing);
  color: #9b9b9b;
  margin-top: -7px;
  border-bottom: none !important;
}
.accessAttributionButtonGrid {
  margin-right: 38px;
  margin-bottom: 36px;
}
.accessAttributionGrid {
  margin-top: 43px !important;
}
.machineCategory {
  display: flex;
  align-items: center;
}
.validationButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
  background-color: #4494d0;
}
.cancelButton {
  width: 150px;
  height: 51px;
  border-radius: 3px;
}
.tooltipContainer {
  margin-left: 10px;
  margin-top: 15px;
}
.tooltip {
  max-width: 600px;
  font-size: 13px;
}
.labelWithTooltip {
  display: flex;
  & span {
    margin-right: 10px;
  }
}
.loaderSave {
  height: 20px;
}
